import './insta.js'
import moment from 'moment';

jQuery(document).ready(function ($) {

  // newsletter
  $('.newsletter-trigger, .close').click(function () {
    $('.newsletter-popup').toggleClass('active')
  })

  // Tour
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/Alex%20Isley/events?app_id=45PRESS_alex-isley',
    method: 'GET',
    dataType: 'json', 
    xhrFields: {
      withCredentials: false
    },
    error: () => {
    },
    success: data => {
      const events = $('#events');
      let html = ''
      let n = 0

      if (data.length) {
        for (let event of data) {
          n++
          const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
          html += '<div class="event display-flex" data-bit-id="' + event.id + '">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMMM DD Y') + '</div>';
          html += '<div class="event-location">' + event.venue.city + ', ' + event.venue.region + '</div>';
          html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '<div class="event-links-wrapper">'
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn btn-black">' + offer.type + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('No upcoming events.');
      }

      console.log(n)
      if (n < 9) {
        $("#toggle-dates").hide();
      }

      $('#events > *:gt(7)').hide();
    }
  });

  $('#toggle-dates').on('click', function (e) {
    e.preventDefault();

    if ($(this).html() === 'Show Less') {
      $(this).html('Show More');
      $('#events > *:gt(7)').fadeOut();
      $("html, body").animate({ scrollTop: $("#tour").offset().top });
    } else {
      $(this).html('Show Less');
      $('#events > *').fadeIn();
    }
  });

});